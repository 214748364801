.btn {
  font-weight: 600;

  &-c-check {
    &:active,
    &:focus {
      box-shadow: $btn-active-shadow !important;
    }

    @include button-variant(
      $white,
      $radio-group-border-color,
      $primary,
      $hover-background: white,
      $hover-border: $primary,
      $hover-color: $primary,
      $active-background: $primary,
      $active-border: $primary,
      $active-color: white,
      $disabled-background: $radio-group-border-color,
      $disabled-border: $radio-group-border-color,
      $disabled-color: white
    );
  }
  &-collapse {
    transition: ease 350ms all;
    width: 39px;
    height: 44px;
    border-color: $primary;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $secondary;
    &:focus,
    &:hover {
      border-color: $primary;
      color: $secondary;
    }
    &.open {
      color: $primary;
      border-color: $secondary;
    }
  }
}
