.customSelect {
  .select__control {
    border: $border-width solid $input-border-color;
    border-radius: $border-radius;
    min-height: $input-min-height;

    &:hover,
    &.select__control--is-focused {
      border: 2px solid $primary;
      box-shadow: none;
    }
    &.select__control--menu-is-open {
      .select__indicator {
        transform: rotate(180deg);
      }
    }
  }

  &.hasValue {
    .select__control {
      border-color: $primary;
    }
  }

  .select__indicator {
    color: $secondary;
    transition: ease 350ms all;
    &:hover {
      color: $secondary;
    }
  }
  .select__value-container {
    cursor: text;
  }
}

.is-invalid {
  .select__control {
    &,
    &:hover {
      border-color: $form-feedback-icon-invalid-color !important;
      background-image: escape-svg($form-feedback-icon-invalid);
      background-repeat: no-repeat;
      background-position: right add(2.3em, $input-height-inner-quarter, true)
        center;
      background-size: $input-height-inner-half $input-height-inner-half;
      box-shadow: none !important;
      transition: none !important;
    }
  }
}
