.form-multi-step-indicator {
    @extend .p-0;
    @extend .text-center;

    margin-bottom: 30px;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;

    li {
        list-style-type: none;
        color: $dark;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        width: 33.33%;
        float: left;
        position: relative;
        letter-spacing: 1px;

        &:before {
            content: counter(step);
            counter-increment: step;
            width: 24px;
            height: 24px;
            line-height: 22px;
            display: block;
            font-size: 14px;
            color: $gray-400;
            background: $white;
            border-radius: 50%;
            margin: 0 auto 10px auto;
            border-color: $gray-400;
            border-width: 1px;
            border-style: solid;
        }

        &:after {
            content: '';
            width: 100%;
            height: 2px;
            background: $gray-200;
            position: absolute;
            left: -50%;
            top: 9px;
            z-index: -1;
            /*put it behind the numbers*/
        }

        &:first-child:after {
            /*connector not needed before the first step*/
            content: none;
        }

        &.active {

            &:before,
            &:after {
                border-color: $dark;
                color: $dark;
            }

            &:after {
                background: $primary;
            }
        }

        &.completed {
            color: $success;

            &:before {
                background-color: $success;
                border-color: $success;
                color: $white;
            }

            &:after {
                background: $primary;
            }
        }
    }
}