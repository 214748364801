.customCheck {
  &.is-invalid {
    border-color: $form-feedback-icon-invalid-color !important;
  }

  .customCheckIcon {
    cursor: pointer;
    width: 39px;
    height: 44px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px $primary;

    &.disabled {
      border-color: $dark !important;
      background-color: lighten($dark, 35%) !important;
      cursor: default !important;
    }
  }
}

.form-check {

  label,
  input {
    cursor: pointer
  }
}