.tb-postcode {
  display: flex;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  caret-color: $secondary;
  // &:disabled,
  // &.disabled {
  //   &::placeholder {
  //     opacity: 0;
  //   }
  // }
}
.label-border {
  label {
    position: relative;
    padding-left: 0.75rem;
    &:before {
      content: '';
      position: absolute;
      top: 0.125rem;
      left: 0;
      display: block;
      width: 4px;
      border-radius: 10rem;
      height: 1.25rem;
      background-color: $secondary;
    }
  }
}
