.customRadio {
  &Wrapper {
    &.is-invalid {
      label {
        border-color: $form-feedback-icon-invalid-color !important;
      }
    }

    &Item {
      input[type='radio'] {
        & + label {
          border-color: $radio-group-border-color;
          color: $primary;
          &:hover {
            border-color: $primary;
          }
        }
        
        &:checked + label {
          color: $primary;
          border-color: $primary;
          box-shadow: $btn-active-shadow !important;
        }
      }
    }
  }
}
