@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'variables';
@import 'utilities';
// @import 'bootstrap/scss/utilities/api';

@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/bootstrap';

@import 'theme';

@import 'controls/border';
@import 'controls/form';
@import 'controls/button';
@import 'controls/input';
@import 'controls/check';
@import 'controls/radio';
@import 'controls/select';
@import 'controls/progress';
@import 'controls/list';
@import 'containers';
@import 'multi-step';

:root {
  scroll-behavior: auto;
}

.logo {
  img {
    max-width: 60px;
  }
}

.postcode-item {
  cursor: pointer;

  &:hover {
    background-color: $hover-bg-color;
  }
}

.instantQuoteBrokerInfo,
.instantQuoteBackOfficeInfo {
  background-color: $cg-dark-blue;
  color: $white;
  font-size: 0.8125rem;
  padding: 0.5rem 0;

  div {
    @extend .container;
    @extend .container-body-lg;

    a {
      float: right;
      color: #fff;
    }
  }

}