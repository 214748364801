ul.custom-list {
    list-style: none;
    padding-left: 1rem;
    li {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: -1rem;
            top: 9px;
            width: 7px;
            height: 7px;
            display: inline-block;
            background-color: $secondary;
            border-radius: 20px;
        }
    }
}