$enable-negative-margins: true;
$grid-breakpoints: (
  xs: 0,
  sm: 440px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 440px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, '$container-max-widths'
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.25rem !default;
$grid-row-columns: 6 !default;

$font-size-base: 1rem !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base * 1.125 !default;

$font-family-sans-serif: 'Montserrat', sans-serif, Roboto, 'Helvetica Neue',
  'Noto Sans', 'Liberation Sans', Arial, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji' !default;
// $enable-shadows: true !default;
$primary: #1a00ac;
$cg-dark-blue: darken(desaturate(adjust-hue($primary, -38), 7.84), 3.73); // #064b93
$hover-bg-color: lighten(desaturate($primary, 48.78), 58.24); // #e3e0f5
$secondary: #fc2766;
$gray-100: #f4f4f4;
$input-bg-color: $gray-100;

$headings-margin-bottom: 1.5rem;
$headings-font-weight: 600;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$navbar-light-active-color: $secondary;
$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;

$border-radius: 20px;
$border-width: 2px;
$border-radius-2xl: 1.25rem;

$form-label-margin-bottom: 1rem;

$form-check-input-width: 1.25em;
$form-check-input-focus-border: $secondary !default;
$form-check-input-focus-box-shadow: 0 0 0 0.25rem rgba($secondary, 0.25) !default;
$form-check-input-checked-bg-color: $secondary !default;
$form-check-input-checked-border-color: $secondary !default;

$input-min-height: 3rem !default;
$input-border-color: #dedede;
$radio-group-border-color: $input-border-color;

$btn-active-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-size: 18px;

$input-bg: $input-bg-color;
$input-border-color: $input-bg-color;
$input-focus-border-color: $primary;

$input-disabled-bg: #fff;
$input-disabled-border-color: $gray-100;

// Progress bars

// scss-docs-start progress-variables
$progress-height: 0.5rem !default;
$progress-bg: $gray-100 !default;
$progress-bar-bg: $primary !default;
$progress-bar-bg-radius: $border-radius;
$progress-border-radius: 0;
// scss-docs-end progress-variables

$icon-lookup: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48' viewBox='0 0 48 48' fill='#{$secondary}'%3E%3Cpath d='M0 0h24v24H0V0z' /%3E%3Cpath d='M21 3L3 10.53v.98l6.84 2.65L12.48 21h.98L21 3z'%3E%3C/path%3E%3C/svg%3E");
$icon-check: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48' viewBox='0 0 48 48' fill='#{$secondary}'%3E%3Cpath d='M18.9 36.9 6.5 24.5l3.4-3.4 9 9 19.15-19.15 3.4 3.4Z'%3E%3C/path%3E%3C/svg%3E");
$icon-cross: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48' viewBox='0 0 48 48' fill='#{$secondary}'%3E%3Cpath d='m12.45 38.85-3.3-3.3L20.7 24 9.15 12.45l3.3-3.3L24 20.7 35.55 9.15l3.3 3.3L27.3 24l11.55 11.55-3.3 3.3L24 27.3Z'%3E%3C/path%3E%3C/svg%3E");